import * as React from 'react';

import Layout from '../components/Layout';

const IndexTemplate = ({ pageContext, location }) => {
  const { node, navigations, localizations } = pageContext;
  return (
    <Layout
      navigations={navigations}
      location={location}
      localizations={localizations}
    >
      <>{node.title}</>
    </Layout>
  );
};

export default IndexTemplate;
